<template>
  <!-- 字典表页面 -->
  <div class="container">
    <a-row>
      <a-col :span="4">
        <vue-scroll :ops="ops" style="width: 100%; height: 60vh; margin-top: 12px">
          <a-tree
            :selected-keys="selectedKeys"
            :tree-data="treeData"
            :auto-expand-parent="autoExpandParent"
            default-expand-all
            @expand="onExpand"
            @select="onSelect"
            :expanded-keys="expandedKeys"
            :replaceFields="replaceFields"
          />
        </vue-scroll>
      </a-col>
      <a-col :span="20" class="right">
        <div class="right-content">
          <a-spin tip="Loading..." :spinning="spinning">
            <div class="table-page-search-wrapper">
              <a-form layout="inline">
                <a-row :gutter="48">
                  <a-col :md="8" :sm="24">
                    <a-form-item label="名称">
                      <a-input v-model="queryParam.name" allow-clear placeholder="请输入" />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="字典代码 (唯一)">
                      <a-input v-model="queryParam.code" allow-clear placeholder="请输入" />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-button type="primary" @click="initTableData">查询</a-button>
                    <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                  </a-col>
                  <a-col :md="24" :sm="24" style="margin-bottom: 8px">
                    <a-button type="primary" icon="plus" @click="$refs.addForm.add(selectedKeys[0])">新建</a-button>
                  </a-col>
                </a-row>
                <!--  v-if="showAddFlag && selectedKeys[0] != 0" -->
              </a-form>
            </div>
            <a-table
              :columns="columns"
              :data-source="datas"
              :pagination="false"
              :bordered="true"
              :scroll="{ x: 1300 }"
              :loading="loading"
            >
              <!-- 图片插槽 -->
              <span slot="dictImage" slot-scope="text, record">
                <img :src="record.images" v-if="record.images" style="height: 50px" />
              </span>
              <!-- 操作插槽 -->
              <span slot="action" slot-scope="text, record">
                <a @click="$refs.editForm.edit(record)">编辑</a>
                <a-divider type="vertical" />
                <!-- v-if="selectedKeys[0] != 0" -->
                <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => deleteDictData(record)">
                  <a>删除</a>
                </a-popconfirm>
              </span>
            </a-table>
            <!-- 分页组件 -->
            <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
              <a-pagination
                :pageSize="page.pageSize"
                :current="page.pageNo"
                show-size-changer
                :page-size-options="['10', '20', '50', '100']"
                show-quick-jumper
                :total="total"
                :show-total="(total) => `共 ${total} 条`"
                @change="pageChange"
                @showSizeChange="sizeChange"
              ></a-pagination>
            </div>
          </a-spin>
        </div>
      </a-col>
    </a-row>
    <add-form ref="addForm" @ok="handleOk" />
    <edit-form ref="editForm" @ok="findTreeData" />
  </div>
</template>

<script>
import { dictPageList, dictTree, dictDelete, dictList } from '@/api/modular/mallLiving/dataDict'
import addForm from './addForm'
import editForm from './editForm'
export default {
  components: {
    addForm,
    editForm,
  },
  data() {
    return {
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      currentRecord: {},
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: '#333', //滚动条颜色
          opacity: 0.5, //滚动条透明度
          'overflow-x': 'hidden',
        },
      },
      queryParam: {},
      datas: [],
      selectable: false,
      title: '',

      columns: [
        {
          title: '字典名称',
          dataIndex: 'name',
          align: 'center',
          width: '200px',
        },
        {
          title: '字典值',
          dataIndex: 'dictValue',
          align: 'center',
          width: '150px',
        },
        {
          title: '描述',
          dataIndex: 'dictDescribe',
          align: 'center',
          width: '200px',
        },
        {
          title: '字典代码 (唯一)',
          dataIndex: 'code',
          align: 'center',
          width: '200px',
        },
        {
          title: '图片',
          dataIndex: 'images',
          align: 'center',
          width: '180px',
          scopedSlots: { customRender: 'dictImage' },
        },
        {
          title: '排序',
          dataIndex: 'sort',
          align: 'center',
          width: '80px',
        },
        {
          title: '更新时间',
          dataIndex: 'createTime',
          align: 'center',
          width: '150px',
        },
        {
          title: '操作',
          width: '150px',
          dataIndex: 'action',
          fixed: 'right',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],

      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      treeData: [], //树菜单数据
      type: 1,
      // 弹出层
      visible: false,
      standardrequest: false,
      detailShow: false,
      Treetype: 'top', // top center bottom
      // 加载状态
      spinning: false,
      expandedKeys: [],
      // 多级联动
      checkId: '',
      loading: false,
      currentItem: {},
      currentOrgName: '',
      replaceFields: {
        key: 'id',
      },
      parentId: '',
      total: 0,
      showAddFlag: false,
    }
  },
  props: [],
  computed: {},
  filters: {},
  mounted() {
    this.parentId = 0
    this.findTreeData()
    this.initTableData()
  },
  methods: {
    async findTreeData() {
      let data = await dictTree({})
      if (data.code == 200) {
        this.expandedKeys = []
        this.selectedKeys = []
        var list = [{ id: 0, title: '顶级', children: [] }]
        var dataList = data.data
        dataList = dataList.filter((item) => {
          return item.code != 'COIN'
        })
        list[0].children = dataList
        this.expandedKeys.push(0)
        this.selectedKeys.push(0)
        this.treeData = list

        this.queryTableData()
      }
    },

    initTableData() {
      this.page.pageNo = 1
      this.queryTableData()
    },

    async queryTableData() {
      this.loading = true
      var params = {
        page: this.page,
        queryParam: {
          parentId: this.parentId,
          ...this.queryParam,
        },
      }
      dictPageList(params)
        .then((res) => {
          this.loading = false
          // if (res.success) {
          //   if(this.parentId == 0){
          //     this.datas = res.data.rows.filter(item =>{
          //       return item.code != 'COIN'
          //     })
          //     this.total = parseInt(res.data.totalRows)-1
          //   }else{
          this.datas = res.data.rows
          this.total = res.data.totalRows
          // }

          // }
        })
        .finally((err) => {
          this.loading = false
        })
    },

    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.queryTableData()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.queryTableData()
    },

    deleteDictData(record) {
      this.spinning = true
      dictDelete({ id: record.id })
        .then((res) => {
          if (res.success) {
            this.$message.success('删除成功')
            this.findTreeData()
          }
          this.spinning = false
        })
        .finally((err) => {
          this.spinning = false
        })
    },
    //子组件操作成功后刷新页面
    handleOk() {
      this.findTreeData()
      this.initTableData()
    },
    onExpand(expandedKeys) {
      console.log('onExpand', expandedKeys)
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    // 选中树状菜单执行回调
    onSelect(selectedKeys, info) {
      let that = this
      if (selectedKeys[0] !== undefined) {
        that.selectedKeys = selectedKeys
        that.parentId = selectedKeys[0]
        that.showAddFlag = false
        that.treeData[0].children.forEach((item) => {
          if (item.id == selectedKeys[0] && item.parentId == '0') {
            if (
              item.code == 'STORE_BOND_EXPLAIN' ||
              item.code == 'USER_RETURN_REASON' ||
              item.code == 'WITHDRAWAL' ||
              item.code == 'LOGISTICS_COMPANY_CODE' ||
              item.code == 'USER_REPORT_REASON' ||
              item.code == 'VIDEO_LIVE_REPORT_REASON' ||
              item.code == 'CENT_COMMISSION' ||
              item.code == 'BANK_TYPE_LIST'
            ) {
              that.showAddFlag = true
            }
          }
        })
        that.initTableData()
      }
    },
  },
}
</script>

<style lang="less" scoped>
// @import url(); 引入公共css
.container {
  min-width: 1000px;
}
.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}
.selected {
  background-color: #bae7ff;
}
.right-content {
  box-sizing: border-box;
  padding: 10px;
}
.icon {
  font-size: 20px;
  margin: 0 5px;
}
</style>
